import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import logo from '../Images/abw.png';
import camera from '../Images/camera-img.png';
import demo from '../Images/mocky.png';
import { Box} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core//Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import { Link } from 'react-router-dom';
import faceIcon from '../Images/face-scan.png';
import fingerIcon from '../Images/finger-scan.png';
import DatePicker from 'react-date-picker';
import "react-datepicker/dist/react-datepicker.css"
import { apiAxios2 } from '../Util/ApiAxios'
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import Nav from "../Component/Nav";
import loader from "../Images/loader.gif"

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import { MotionAnimate } from 'react-motion-animate'



function UpdateProfile (props) {

    const classes = useStyles();
    const [showLoader, setShowLoader] = React.useState(false);

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [dob, setDOB] = React.useState(new Date());
    // const [dob, setDOB] = React.useState(undefined);
    const [gender, setGender] = React.useState("Male");
    const [height, setHeight] = React.useState("");
    const [weight, setWeight] = React.useState("");

    const [selected, setSelected] = React.useState("");
    const [genderError, setGenderError] = React.useState(false)

    const [firstNameError, setFirstNameError] = React.useState(false);
    const [lastNameError, setLastNameError] = React.useState(false);
    const [dobError, setDobError] = React.useState(false);
    const [heightError, setHeightError] = React.useState(false);
    const [weightError, setWeightError] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(false);
    const [isCheckedError, setIsCheckedError] = React.useState(false);
    const [userId, setUserId] = React.useState("")
    const [dobFormat, setDobFormat] = React.useState("")
    const [pageCounter, setPageCounter] = React.useState(1);
    


    useEffect(()=>{
      
      getData();

       
      


    },[])


  // prefill form data on page load

    const getData = () => {
        let userDetails = JSON.parse(localStorage.getItem("user_details"));
    
        let data = {
          
        };
        apiAxios2
          .post("/users/profile/view", data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: userDetails.id_token,
            },
          })
          .then((response) => {
            console.log(response.data.profile)
            
            let  data = response.data.profile
           
            setFirstName(data.first_name)
            setLastName(data.last_name)
            setDOB(new Date(data.date_of_birth))
            setSelected(data.gender)
            setHeight(data.height)
            setWeight(data.weight)
            console.log(selected)
            if(data.date_of_birth == null){
                setDOB(undefined)
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };


    // gender selection

    const handleChangeSelected = (event) => {

        //console.log("Selected = ", selected);
        setSelected(event.target.value);
       
      };

   // getting terms checkbox value
   const getCheckValue = (e)=>{
    
    const {checked}= e.target;
    setIsChecked(checked)
    console.log(isChecked)
 }



    // updating profile details

    const onUserSubmit = ()=>{
        
    let isValid = true;

    if(firstName !== null && firstName.length > 0){
        setFirstNameError(false);
    }else{
        setFirstNameError(true);
        isValid = false;   
     }
    if(lastName !== null && lastName.length > 0){
        setLastNameError(false);
     }else{
    setLastNameError(true);
         isValid = false;   
    }
    if(height !== null && height >=30 && height <= 250){
        setHeightError(false);
    }else{
        setHeightError(true);
        isValid = false;   
    }
    if(weight !== null && weight >= 5 && weight <= 220){
        setWeightError(false);
    }else{
        setWeightError(true);
        isValid = false;   
    }
    if(selected === null) {
        setGenderError(true)
    }else {
        setGenderError(false)
    }
    
    
    //set date based on birthday at 01:00:00 hours GMT+0100 (CET)

    var myBirthday
    var currentDate 
    var myAge
    if(dob === null){
        // var myBirthday = 0
        // var currentDate =0
        // var myAge = 0
        var myBirthday = undefined;
        var currentDate = undefined;
        var myAge = undefined;
    }else {
         var myBirthday = new Date(dob)
         var currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';
         var myAge = ((Date.now(currentDate) - myBirthday) / (31557600000));
    }

            
            
    // var myBirthday = new Date(dob);
            
    // console.log(myBirthday)


    // // set current day on 01:00:00 hours GMT+0100 (CET)
    // var currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';

    // // calculate age comparing current date and borthday
    // var myAge = ((Date.now(currentDate) - myBirthday) / (31557600000));

    console.log("Age : ", myAge);

    if(myAge !== null && myAge >= 18 && myAge <=110){
        setDobError(false);
    }else{
        setDobError(true);
        isValid = false;   
    }








    if(isValid) {
        setShowLoader(true)
        let userDetails = JSON.parse(localStorage.getItem("user_details")) 

    // console.log(userDetails.id_token)

    // changing date format for the payload

        let DOB =  moment(dob).format("YYYY-MM-DD");
    
        
        let data = {
      
            first_name: firstName,  
            last_name: lastName,
            dob: DOB,
            is_terms_accepted: true,
            user_id: userDetails.user_id,
            gender: selected,
            height: height,
            weight: weight
            
        
        
            };

    apiAxios2
          .post("/users/profile/update", data, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": userDetails.id_token
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.statusCode == 200) {
                toast.success(response.data.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  
                });
                
                const timer = setTimeout(() => {
                    props.history.push("/dashboard")
                  }, 2000);
                
                
                
                  setShowLoader(false)
                
      
              } else {
                toast.error(response.data.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setShowLoader(false);
              }
            })
        
            .catch((error) => {
                toast.error(error.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setShowLoader(false);
              });
        
        

    
    }

    }

return <>
 
 <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> 
    <Box className={classes.Scan}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" style={{ height:'50%' }} />
          <Box style={{ marginRight: -15 }}>
            <Nav />
          </Box>
        </Box>
        <Box style={{display:'flex',justifyContent:'space-between',flexDirection:'column', height:'90%'}}> 
            <Box className={classes.basic}>
                <Box className={classes.loginform}>
                    <p style={{fontSize:18,color:'#C7222A',textAlign:'left',fontFamily:'Poppins',fontWeight:'500'}}>Update Profile &nbsp;&nbsp;&nbsp;</p>
                    <MotionAnimate
                        animation='fadeInUp'
                        reset={true}
                        delay={0.5}
                        speed={1}>
                        <form style={{textAlign:'left'}}>
                            
                            <>
                            <FormLabel id="demo-radio-buttons-group-label" className={classes.customlabel}>First Name</FormLabel>
                            <TextField className={classes.input} placeholder="Enter First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text"/>
                            {firstNameError ? (
                                <p
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: 'right'
                                }}
                                >
                                First name is mandatory.
                                </p>
                            ) : (
                                <></>
                            )}

                            <FormLabel id="demo-radio-buttons-group-label" className={classes.customlabel}>Last Name</FormLabel>
                            <TextField className={classes.input} placeholder="Enter Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} type="text"/>
                            {lastNameError ? (
                                <p
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: 'right'
                                }}
                                >
                                Last name is mandatory.
                                </p>
                            ) : (
                                <></>
                            )}

                            </>
                              
                           
                            <> 

                            <FormControl style={{width:'100%', marginBottom:'5%' }}>
                                <FormLabel id="demo-radio-buttons-group-label" className={classes.customlabel}>Gender</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    
                                    name="radio-buttons-group"
                                    value = {selected}
                                >
                                    <FormControlLabel className={classes.customRadio} value="female" control={<Radio selected={selected} onChange={handleChangeSelected}   />} label="Female" />
                                    <FormControlLabel className={classes.customRadio} value="male" control={<Radio selected={selected} onChange={handleChangeSelected}   />} label="Male" />
                                    <FormControlLabel className={classes.customRadio} value="others" control={<Radio selected={selected} onChange={handleChangeSelected}   />} label="Other" />
                                </RadioGroup>
                                {genderError ? (
                                <p
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: 'right'
                                }}
                                >
                                please select a Gender
                                </p>
                            ) : (
                                <></>
                            )}
                            </FormControl>

                            <FormLabel id="demo-radio-buttons-group-label" className={classes.customlabel}>Date Of Birth</FormLabel>
                            <DatePicker className={classes.datepicker} value={dob} placeholderText="Enter Date of Birth" selected={Date.parse(dob)} maxDate={moment().toDate()} onChange={(date) => setDOB(date)} format="dd-MM-y" clearIcon={null} style={{border: '1px solid #C7222A', padding: '0 15px'}} />
                            {dobError ? (
                                <p
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: 'right'
                                }}
                                >
                                Enter a valid age between 18 to 110 years.
                                </p>
                            ) : (
                                <></>
                            )}    

                            </>
                           


                          
                            <> 
                            <FormLabel id="demo-radio-buttons-group-label" className={classes.customlabel}>Height</FormLabel>
                            <TextField className={classes.input} value={height} onChange={(e)=>setHeight(e.target.value)} placeholder="Enter Height in cms"  type="number"/>
                            {heightError ? (
                                <p
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: 'right'
                                }}
                                >
                                Height is mandatory, please enter a valid height.
                                </p>
                            ) : (
                                <></>
                            )}

                            <FormLabel id="demo-radio-buttons-group-label" className={classes.customlabel}>Weight</FormLabel>
                            <TextField className={classes.input} value={weight} onClick={window.scrollTo(0, 500)} onChange={(e) => setWeight(e.target.value)} placeholder="Enter Weight in Kgs"  type="number"/>
                            {weightError ? (
                                <p
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: 'right'
                                }}
                                >
                                Weight is mandatory, please enter a valid weight.
                                </p>
                            ) : (
                                <></>
                            )}
                            

                           


                          

                            </>
                           
                            
                             
                          
                         
                            <> 
                            
                                <Box style={{display:'flex',justifyContent:'center',marginTop:'10%',marginBottom:60,}}>  
                                        {showLoader?
                                        <>
                                        <img src={loader} alt="loader" style={{width:'50px',height:'50px'}} />
                                        </>
                                        :        
                                        <>       
                                              
                                        <Button size="large" className={classes.loginbtn} onClick={onUserSubmit}>
                                        Update 
                                        </Button>
                                        </>
                                        }
                                </Box>
                            </>
                           
                          
                           
                            
                        </form>
                    </MotionAnimate>    
                </Box>
            </Box>   
        </Box>
      </Box>
     
  



  </>





}

export  default UpdateProfile;


const useStyles = makeStyles(() => ({
    Scan:{
        display:'flex',
        height:'100vh',
        left:'0',
        right: '0',
        background: 'linear-gradient(180deg, #c1bfbf14, white)',
        flexDirection:'column',
        '& a':{
            textDecoration:'none'
        }
    },
    customlabel:{
        textAlign: 'left !important',
        color: '#000000',
        paddingBottom: '5px',
        fontSize: '14px'
    },
    customRadio:{
        border: '1px solid #000000',
        borderRadius: '10px',
        height: '50px',
        margin: '0',
        background: '#fff',
        marginBottom: '8px',
        '& .MuiRadio-colorSecondary.Mui-checked':{
            color: '#C7222A'
        }
    },
    optionIcon:{
       width: '100%',
       textAlign: 'center',
       height: '50%',
       '& h4':{
        fontFamily:'Poppins',
        color:'#C7222A',
        fontWeight:'400',
        fontSize:16,
        marginTop:20,
        marginBottom:10,
        }, 
    },
    ScanInfo:{
        width:'100%',
        background:'linear-gradient(180deg, #C7222A -92.7%, #C7222A 92.7%)',
        height:'38vh',
        '& p':{
            fontFamily:'Poppins',
            color:'rgba(255,255,255,0.7)',
            fontWeight:'400',
            fontSize:12,
            padding:'0 20px',
            marginTop:0,
            marginBottom:30,
            lineHeight:1.6,
        },
        '& h4':{
            fontFamily:'Poppins',
            color:'rgba(255,255,255,0.8)',
            fontWeight:'400',
            fontSize:16,
            marginTop:20,
            marginBottom:10,
        },
        '& h3':{
            fontSize:16,
            fontFamily:'Poppins',
            color:'#fff',
            fontWeight:'400',
            marginTop:40,
            marginBottom:0,
            '& span':{
                fontSize:24,
                fontWeight:'600',
                color:'#C7222A',
                marginRight:5,
            }
        }
    },
    ScanCamera:{
        width:'100%',
        height:'49.7vh'
    },
    logo: {
        display: "flex",
        padding: '0 5%',
        justifyContent: "space-between",
        alignItems: "center",
        height: "12%",
        backgroundColor: "#fff",
    },
    CancelBtn:{
        fontFamily:'Poppins',
        fontSize:16,
        color:'#C7222A',
        textTransform:'capitalize',
        borderRadius:50,
        padding:'5px 20px'
    },
    input:{ 
        border:'none',
        borderRadius:'10px',
        height:'50px',
        width:'100%',
        marginBottom:'20px',
        marginTop:'3%'
    },
    datepicker:{ 
        border:'none',
        borderRadius:'10px',
        height:'50px',
        width:'100%',
        backgroundColor: '#fff',
        marginBottom:'20px',
        padding: '0 0 0 5%',
        border: '1px solid #000000'
    },
    basic:{
        width:'100%',
        height: '50%',
        paddingTop: '5%',
        paddingBottom: '10%',
        '& .MuiInput-underline:before':{
            display:'none'
        },
        '& .MuiInput-underline:after':{
            display:'none'
        },
        '& .MuiInput-formControl':{ 
            height:'50px',
            
        },
        '& .MuiInputBase-input':{
            height:'50px',
            borderRadius:'10px',
            background:'#fff',
            padding:'0 15px',
            border:'1px solid #000000'
        },
        '& .MuiInput-input:focus':{
            border:'1px #7087A7 solid',
            boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
        }
    },
    loginbtn:{
        background:'linear-gradient(90deg, #C7222A 0%, #C7222A 100%)',
        padding:'0 20px',
        width:'142px',
        height:'50px',
        borderRadius:'50px',
        color:'#fff',
        marginTop:'0',
        textTransform:'capitalize',
        // marginRight:'100px',
        marginBottom:'10px'
        

        
    },
    loginbtn2:{
        
        padding:'0 20px',
        width:'142px',
        height:'50px',
        borderRadius:'50px',
        color:'#C7222A',
        marginTop:'0',
        textTransform:'capitalize',
        
    },
    loginform: {
        padding: '0 30px',
        height:'88vh',
        overflowY:'scroll'
    }

   }));

