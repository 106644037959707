import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../Images/abw.png";
import instruction1 from "../Images/instruction1.png";
import instruction2 from "../Images/instruction2.png";
import camera from "../Images/camera-img.png";
import demo from "../Images/mocky.png";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core//Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import faceIcon from "../Images/face-scan.png";
import fingerIcon from "../Images/finger-scan.png";
import fingerIconComingSoon from "../Images/finger-scan-comingsoon.png";
import Nav from "../Component/Nav";
import loader from "../Images/loader.gif";

import { apiAxios, apiAxios2, API_KEY, SECRET_KEY } from "../Util/ApiAxios";
import { useState, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MotionAnimate } from "react-motion-animate";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "5% 20%",
    transform: "translate(-50%, -50%)",
    borderRadius: '20px'
  },
};

const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "5% 5%",
    width: '85%',
    height: '75%',
    transform: "translate(-50%, -50%)",
    borderRadius: '20px'
  },
};

function SelectOption(props) {
  const classes = useStyles();

  const [userDetails, setUserDetails] = React.useState("");

  const [isMac, setIsMac] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [scanType, setScanType] = React.useState("");

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalInstructIsOpen, setInstructIsOpen] = React.useState(false);

  const user = JSON.parse(localStorage.getItem("user_details")); 

  const openModal = (flag) => {
    setSelected(""); 
    setScanType(flag);
    setIsOpen(true);
  }

  const openInstructModal = () => {
    setIsOpen(false);
    setInstructIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#C7222A";
  }

  function closeModal() {
     
    setIsOpen(false);
  }

  function closeInstructModal() { 
    setInstructIsOpen(false);
  }

  const startScan = (flag) => {
    setUserDetails(JSON.parse(localStorage.getItem("user_details")));
    setShowLoader(true);

    if (localStorage.getItem("scan_result")) {
      localStorage.removeItem("scan_result");
    }

    let data = {
      action: "create_scan_UL",
      employee_id: localStorage.getItem("emp_id")
        ? localStorage.getItem("emp_id")
        : "",
    };

    apiAxios2
      .post("/users/sdk/test_api_private", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.id_token,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.statusCode == 200) {
          localStorage.setItem("scan_token", response.data.scan_token);
          localStorage.setItem("posture", selected);
          setShowLoader(false);
          if (flag == 1) {
            props.history.push("/face-scan");
          } else {
            props.history.push("/finger-scan");
          }
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowLoader(false);
      });
  };

  const [selected, setSelected] = React.useState("");

  const handleChangeSelected = (event) => {

    //console.log("Selected = ", selected);
    setSelected(event.target.value);
   
  };

  useEffect(() => {
    if (navigator.appVersion.indexOf("Mac") != -1) {
      setIsMac(true);
    } else {
      setIsMac(false);
    }

    if (localStorage.getItem("scan_result")) {
      localStorage.removeItem("scan_result");
    }
    //test again
  }, []);

  return (
    <>
      <Box className={classes.Scan}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" style={{ height:'50%', marginLeft: 25 }} />
          <Box style={{ marginRight: 15 }}>
            <Nav />
          </Box>
        </Box>
        {showLoader ? (
          <Box style={{ margin: "50% 0", width: "100%", textAlign: "center" }}>
            <img
              src={loader}
              alt="loader"
              style={{ width: "80px", height: "80px" }}
            />
            <p>Initiating Scan Process...</p>
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "80%",
            }}
          >
            <Box className={classes.optionIcon}>
              <img
                src={faceIcon}
                alt="face scan"
                style={{ padding: "10% 0 0 0", height: "40%" }}
                onClick={() => openModal(1)}
              />
              <h4>Start A Face Scan</h4>
              <p className={classes.subScan}>
                Scan your vitals using your front camera.
              </p>
            </Box>

            {/* <Box className={classes.optionIcon}>
               <img src={fingerIconComingSoon} alt="finger scan" style={{padding:'0 0 0 0', height: '40%', opacity: '1'}} />
                <h4 style={{opacity: '0.35', filter: 'grayscale(1)'}}>Start A Finger Scan</h4>
                <p className={classes.subScan} style={{opacity: '0.35'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
            </Box>   */}

            <Box className={classes.optionIcon}>
              <img
                src={fingerIcon}
                alt="finger scan"
                style={{ padding: "0 0 0 0", height: "40%" }}
                onClick={() => openModal(2)}
              />
              <h4>Start A Finger Scan</h4>
              <p className={classes.subScan}>
                Scan your vitals by placing your finger <br/>on the rear camera.
              </p>
            </Box>

            <Link to="/dashboard"><Box style={{color:'#C7222A', textAlign: 'center'}}>Cancel</Box></Link>
          </Box>
        )}
      </Box>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Select Posture</h2>
        <div>
          <FormControl style={{ width: "100%", marginBottom: '5%' }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={selected}
              className={classes.radioBtn}
            >
              <FormControlLabel
                className={classes.customRadio}
                value="Resting"
                control={
                  <Radio selected={selected} onChange={handleChangeSelected} />
                }
                label="Resting"
              />
              <FormControlLabel
                className={classes.customRadio}
                value="Standing"
                control={
                  <Radio selected={selected} onChange={handleChangeSelected} />
                }
                label="Standing"
              />
              <FormControlLabel
                className={classes.customRadio}
                value="Walking"
                control={
                  <Radio selected={selected} onChange={handleChangeSelected} />
                }
                label="Walking"
              />
              <FormControlLabel
                className={classes.customRadio}
                value="Exercising"
                control={
                  <Radio selected={selected} onChange={handleChangeSelected} />
                }
                label="Exercising"
              />
            </RadioGroup>
          </FormControl>
          <Button className={classes.cancelBtn} onClick={closeModal}>Cancel</Button>
          { selected ?
          <Button className={classes.activeBtn} onClick={openInstructModal}>Proceed</Button>
          :
          <></>
          }
        </div>
      </Modal>

      <Modal
        isOpen={modalInstructIsOpen}
        onRequestClose={closeInstructModal}
        style={customStyles2}
        contentLabel="Example Modal"
      >
        <h2 style={{color: '#C7222A'}}>How to scan?</h2>

        { (scanType == 1) ?
        <>
          { (navigator.platform.match(/iPhone|iPod|iPad/)) ?
          <div style={{width:'100%', textAlign: 'left'}}>
            <img src={instruction1} alt="instruction" style={{ width: "80%", borderRadius: '20px', marginLeft: '10%' }} />
            <ul style={{listStyleType: 'disclosure-closed', paddingLeft: '5%', color: '#5a5a5a'}}>
              <li>Make sure your face is mostly covered by the white circle.</li>
              <li>The ambient light should be bright and consistent and must be white.</li>
              <li>Keep your face and the phone steady to avoid movements during the scan.</li>
            </ul>
          </div>
          :
          <div style={{width:'100%', textAlign: 'left'}}>
            <img src={instruction1} alt="instruction" style={{ width: "80%", borderRadius: '20px', marginLeft: '10%' }} />
            <ul style={{listStyleType: 'disclosure-closed', paddingLeft: '5%', color: '#5a5a5a'}}>
              <li>Place the blue patch under your eyes and make sure your face is mostly covered by the white circle.</li>
              <li>The ambient light should be bright and consistent and must be white.</li>
              <li>Keep your face and the phone steady to avoid movements during the scan.</li>
            </ul>
          </div>
          }
        </>
        :
        <>
          <div style={{width:'100%', textAlign: 'left'}}>
            <img src={instruction2} alt="instruction" style={{ width: "80%", borderRadius: '20px', marginLeft: '10%' }} />
            <ul style={{listStyleType: 'disclosure-closed', paddingLeft: '5%', color: '#5a5a5a'}}>
              <li>Place your finger on the rear primary camera and the flashlight.</li>
              <li>Make sure the screen looks bright red in color.</li>
              <li>If the flash is not turned on then place some light behind the phone.</li>
            </ul>
          </div>
        </>
        }
        
        {showLoader?
          <>
            <img src={loader} alt="loader" style={{width:'50px',height:'50px', float:'right'}} />
          </>
          : 
          <Button className={classes.activeBtn} style={{float: 'right', width: '150px'}} onClick={() => startScan(scanType)}>Start Scan</Button>
        }
        
      </Modal>

    </>
  );
}

export default SelectOption;
const useStyles = makeStyles(() => ({
  Scan: {
    display: "flex",
    height: "100vh",
    left: 0,
    right: "0",
    background: "linear-gradient(0deg, #f4f4f5, white)",
    flexDirection: "column",
    "& a": {
      textDecoration: "none",
    },
  },
  disabledBtn: {
    background:'#c3c3c3',
        padding:'0 20px',
        width:'100px',
        height:'40px',
        borderRadius:'50px',
        color:'#fff',
        marginTop:'0',
  },
  activeBtn: {
    background:'#C7222A',
        padding:'0 20px',
        width:'100px',
        height:'40px',
        borderRadius:'50px',
        color:'#fff',
        marginTop:'0',
  },
  cancelBtn: {
    background:'#fff',
        padding:'0 20px',
        width:'100px',
        height:'40px',
        marginRight: '4%',
        borderRadius:'50px',
        color:'#C7222A',
        marginTop:'0',
        border: '1px solid #C7222A'
  },
  subScan: {
    fontSize: "0.7em",
    color: "#5c6374",
    margin: "0",
    padding: "0 10%",
    fontFamily: "Poppins",
  },
  optionIcon: {
    width: "100%",
    textAlign: "center",
    height: "50%",
    "& h4": {
      fontFamily: "Poppins",
      color: "#000000",
      fontWeight: "500",
      fontSize: 16,
      marginTop: 10,
      marginBottom: 10,
    },
  },
  radioBtn:{
    '& .MuiRadio-colorSecondary.Mui-checked':{
        color: '#C7222A',
      },
  },
  ScanInfo: {
    width: "100%",
    background: "linear-gradient(180deg, #C7222A -92.7%, #C7222A 92.7%)",
    height: "38vh",
    "& p": {
      fontFamily: "Poppins",
      color: "rgba(255,255,255,0.7)",
      fontWeight: "400",
      fontSize: 12,
      padding: "0 20px",
      marginTop: 0,
      marginBottom: 30,
      lineHeight: 1.6,
    },
    "& h4": {
      fontFamily: "Poppins",
      color: "rgba(255,255,255,0.8)",
      fontWeight: "400",
      fontSize: 16,
      marginTop: 20,
      marginBottom: 10,
    },
    "& h3": {
      fontSize: 16,
      fontFamily: "Poppins",
      color: "#fff",
      fontWeight: "400",
      marginTop: 40,
      marginBottom: 0,
      "& span": {
        fontSize: 24,
        fontWeight: "600",
        color: "#C7222A",
        marginRight: 5,
      },
    },
  },
  ScanCamera: {
    width: "100%",
    height: "49.7vh",
  },
  logo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "12%",
    backgroundColor: "#fff",
  },
}));
