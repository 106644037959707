import axios from "axios";

const baseUrlDev = "https://api-dev-btoc-vitals.carenow.healthcare/v2";

export const apiAxios = axios.create({
  baseURL: baseUrlDev,
});

const baseUrlDev2 = "https://api-sdk-wrapper-app.carenow.healthcare/v2";

export const apiAxios2 = axios.create({
    baseURL: baseUrlDev2,
  });

  

  const baseUrlDev3 = "https://sdk.carenow.healthcare/";

  export const apiAxios3 = axios.create({
      baseURL: baseUrlDev3,
  });


export const API_KEY = "CNCPavm7TAtUD14NRDz9";  //ABW Prod

// export const SECRET_KEY = "aOfzaucVmyf37wXJ9ASsAqVlUkaEXpqqMjWqUQlF";