import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../Images/abw.png";
import { Box } from "@material-ui/core";
import Nav from "../Component/Nav";
import Select from "react-dropdown-select";
import { Bar } from "react-chartjs-2";
import { dateFilter } from "../Util/KeyInsights";
import { apiAxios, apiAxios2 } from "../Util/ApiAxios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router";
import { BsArrowLeftCircle } from "react-icons/bs";
import moment from "moment";
import { Pagination } from "@material-ui/lab";


const genData = () => ({
  labels: ["<18", "18-29", "29-39", "40-49", "59-59", "60-69", "70-79", "80+"],
  datasets: [
    {
      type: "bar",
      label: "Age Group",
      data: [80, 90, 75, 50, 40, 30, 10, 6],
      backgroundColor: [
        "#0085c7",
        "#2cbbe7",
        "#006cb1",
        "#0085c7",
        "#0085c7",
        "#0085c7",
        "#0085c7",
        "#0085c7",
      ],
      borderColor: "white",
      options: {
        scales: {
          xAxes: [{ barPercentage: 0.1 }],
        },
      },
    },
  ],
});

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

function HistoryDetails(props) {
  const classes = useStyles();
  const [vitalsData, setVitalsData] = useState(genData());

  const [paramName, setParamName] = useState("");
  const [message, setMessage] = useState("");

  const [filterOption, setFilterOption] = React.useState("weekly");
  const [vitalsName, setVitalsName] = React.useState("");
  const [vitalsValue, setVitalsValue] = React.useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const currentPageRef = useRef(1);
  const [type, setType] = React.useState("");

  // useEffect(() => {
  //   const interval = setInterval(() =>  setVitalsData(genData()), 5000);

  //   return () => clearInterval(interval);
  // }, []);

  const getData = () => {
    console.log(props.location.state);
    console.log(props);

    let { vitals, name, type } = props.location.state;

    setParamName(name);

    if (vitals == "bp") {
      vitals = "BP";
    } else if (vitals == "heart_rate") {
      vitals = "Heart Rate";
    } else if (vitals == "resp_rate") {
      vitals = "Respiratory Rate";
    } else if (vitals == "oxy_sat_prcnt") {
      vitals = "Oxygen Saturation";
    }

    setType(type);
    setVitalsName(vitals);

    let end_dt = moment(new Date()).format("YYYY-MM-DD");
    let start_dt;
    switch (filterOption) {
      case "weekly":
        start_dt = moment(end_dt).subtract(7, "days").format("YYYY-MM-DD");
        break;
      case "yearly":
        start_dt = moment(end_dt).subtract(1, "years").format("YYYY-MM-DD");
        break;
      case "monthly":
        start_dt = moment(end_dt).subtract(30, "days").format("YYYY-MM-DD");
        break;
      default:
        start_dt = moment(end_dt).subtract(60, "days").format("YYYY-MM-DD");
    }

    let userDetails = JSON.parse(localStorage.getItem("user_details"));

    let data = {
      vital_action: vitals,
      employee_id: localStorage.getItem("emp_id"),
      table_config_rows_per_page: 10,
      table_config_page_no: currentPage,
      //start_date: start_dt,
      //end_date: end_dt,
    };

    apiAxios2
      .post("/users/sdk/list_vitals_data_private", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: userDetails.id_token,
        },
      })
      .then((response) => {
        console.log(response.data.response);

        if (response.data.statusCode == 200) {
          setVitalsData(response.data);
          console.log(response.data.total_pages_count);
          setTotalPages(response.data.total_pages_count);
          // setCurrentPage(response.data.current_page);
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          //setShowLoader(false);
          setMessage(response.data.message);
          setCurrentPage(1);
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        //setShowLoader(false);
      });
  };

  useEffect(() => {
    getData();
  }, [currentPage,filterOption]);


  
  return (
    <div>
      <Box className={classes.History}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" style={{ height:'50px', marginTop: '5%' }} />
          <Box style={{ marginRight: -15 }}>
            <Nav />
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "68vh",
            marginTop: "2vh",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "left",
              marginBottom: 0,
            }}
          >
            { (type == "vitals") ? (
              <h3 style={{ width: "100%", textAlign: 'left' }}>
                <Link to="/dashboard">
                  <BsArrowLeftCircle
                    style={{ marginRight: "10%", color: "#C7222A" }}
                  />
                </Link>
                {vitalsName == "BP" ? "Blood Pressure" : vitalsName}
              </h3>
            ) : (
              <h3 style={{ width: "100%", textAlign: 'left' }}>
                <Link to="/dashboard">
                  <BsArrowLeftCircle
                    style={{ marginRight: "10%", color: "#C7222A" }}
                  />
                </Link>
                {paramName == "sdnn"
                  ? "HRV (SDNN)"
                  : paramName == "co"
                  ? "Cardiac Output"
                  : paramName == "map"
                  ? "Mean Arterial Pressure"
                  : "Vo2Max"}
              </h3>
            )}
            {/* <Select
              options={dateFilter()}
              onChange={(values) =>
                setFilterOption(values[0] ? values[0].value : values.value)
              }
              values={dateFilter().filter(
                (option) => option.value == filterOption
              )}
              searchable={false}
            /> */}
          </Box>
          
          <Box className={classes.RecordTable}>
            {vitalsData.response ? (
              vitalsData.response.map((vitals, index) => (
                <Box key={index} className={classes.Trow}>
                  <Box className={classes.Tdcol}>
                    {moment(vitals.created_at).format("DD-MM-YYYY,  hh:mm A")}
                  </Box>
                  <Box className={classes.Tdcol}>{vitals.posture}</Box>

                  <Box className={classes.Tdcol}>
                    {vitalsName == "BP" ? (
                      <>
                      { paramName == "co" ?
                      <p style={{ textAlign: "right" }}>
                        <span>
                          {vitals.cardiac_out.toFixed(2)}
                        </span>{" "}
                        <br />
                        Ltrs
                      </p>
                      : paramName == "map" ?
                      <p style={{ textAlign: "right" }}>
                        <span>
                          {vitals.map.toFixed(2)}
                        </span>{" "}
                        <br />
                        mmHg
                      </p>
                      :
                      <p style={{ textAlign: "right" }}>
                        <span>
                          {Math.round(vitals.bp_sys)}/
                          {Math.round(vitals.bp_dia)}
                        </span>{" "}
                        <br />
                        mmHg
                      </p>
                      }
                      </>
                    ) : vitalsName == "Heart Rate" ? (
                      <p>
                        {paramName == "sdnn" ? (
                          <>
                            <span>
                              {vitals.sdnn == "--"
                                ? "--"
                                : Math.round(vitals.sdnn)}
                            </span>{" "}
                            ms
                          </>
                        ) : paramName == "vo2max" ? (
                          <>
                            <span style={{ textAlign: "right" }}>
                              {vitals.vo2max}
                            </span>{" "}
                            <br />
                            ml/kg/min
                          </>
                        ) : (
                          <>
                            <span>{vitals.heart_rate}</span> bpm
                          </>
                        )}
                      </p>
                    ) : vitalsName == "Respiratory Rate" ? (
                      <p style={{ textAlign: "right" }}>
                        <span>{vitals.resp_rate}</span> /min
                      </p>
                    ) : (
                      <p>
                        <span>{vitals.oxy_sat_prcnt}</span> %
                      </p>
                    )}
                  </Box>
                </Box>
              ))
            ) : (
              <>{message}</>
            )}
          </Box>
          <Box style={{width:'100%', textAlign: 'center'}}>
            <Pagination
              count={totalPages}
              boundaryCount={1}
              siblingCount={1}
              color="#C7222A"
              page={currentPage}
              onChange={(event,value)=>setCurrentPage(value)}
              style={{ float: "right", marginTop: "10px" }}
              size="small"
            />
          </Box>
        </Box>

        {/* <Box className={classes.Trow}>
              <Box className={classes.Tdcol}>24/01/2022, 04:56pm</Box>
              <Box className={classes.Tdcol}>Resting</Box>
              <Box className={classes.Tdcol}><p><span>78</span> bpm</p></Box>
          </Box>
          <Box className={classes.Trow}>
          <Box className={classes.Tdcol}>24/01/2022, 04:56pm</Box>
              <Box className={classes.Tdcol}>Resting</Box>
              <Box className={classes.Tdcol}><p><span>78</span> bpm</p></Box>
          </Box>
          <Box className={classes.Trow}>
          <Box className={classes.Tdcol}>24/01/2022, 04:56pm</Box>
              <Box className={classes.Tdcol}>Resting</Box>
              <Box className={classes.Tdcol}><p><span>78</span> bpm</p></Box>
          </Box> */}
      </Box>
    </div>
  );
}

export default HistoryDetails;
const useStyles = makeStyles(() => ({
  History: {
    display: "flex",
    height: "100vh",
    left: 0,
    right: 0,
    flexDirection: "column",
    padding: "0 30px 0",
    "& a": {
      textDecoration: "none",
    },
    "& .MuiListItem-button:hover": {
      background: "rgba(47, 153, 241, 0.1)",
    },
    "& .css-wmw4vi-ReactDropdownSelect": {
      borderRadius: 40,
      padding: "8px 10px",
    },
    "& h3": {
      fontFamily: "Poppins",
      fontSize: 20,
      color: "#C7222A",
      fontWeight: "400",
      margin: "0 30px 0 0",
    },
  },
  HistoryList: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "rgba(47, 153, 241, 0.06)",
    borderRadius: 10,
    padding: 20,
    marginBottom: 15,
    "& h3": {
      fontSize: 20,
      color: "#C7222A",
      margin: 0,
      fontFamily: "Poppins",
      fontWeight: "400",
    },
    "& h4": {
      fontSize: 24,
      color: "#C7222A",
      margin: 0,
      fontFamily: "Poppins",
      lineHeight: "22px",
      fontWeight: "400",
      textAlign: "center",
    },
    "& p": {
      color: "#5C5C5C",
      fontSize: 15,
      margin: 0,
      fontFamily: "Poppins",
      textAlign: "center",
    },
  },
  RecordTable: {
    width: "100%",
    marginTop: 20,
  },
  Trow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    marginBottom: 10,
    "&:nth-child(even)": {
      background: "rgba(47, 153, 241, 0.1)",
    },
    "&:nth-child(odd)": {
      background: "#FAFAFA",
    },
  },
  Tdcol: {
    fontFamily: "Poppins",
    fontSize: 14,
    "& p": {
      margin: 0,
      "& span": {
        fontSize: 18,
        fontWeight: "600",
      },
    },
  },
  logo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "12%",
  },
}));
