import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../Images/abw.png';
import Email from '../Images/mail.svg';
import Call from '../Images/accept-call.svg';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Nav from '../Component/Nav';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';


function Support() {
    const classes = useStyles();
    function Mailto({ email, subject, body, ...props }) {
        return (
          <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
            {props.children}
          </a>
        );
      }
      const Callto = ({ phone, children }) => {
        return <a href={`tel:${phone}`}>{children}</a>;
      };
  return <div>
      <Box className={classes.Report}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" style={{ height:'50px', marginLeft:'5%' }} />
          <Box style={{ marginRight:5 }}>
            <Nav />
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "88%",
            background:'#fff',
            overflowY:'auto',
          }}
        >
            <Box className={classes.ReportTop}>
            <h2 style={{fontFamily:'Poppins', color:'#C7222A',fontSize:20,textAlign:'left',fontWeight:'600',marginTop:30,}}>Support</h2>
            </Box>
            <Box className={classes.ReportTable}>
            <List component="nav" aria-label="main mailbox folders">
            <ListItem button className={classes.SupportNav}>
            <Mailto email="abw.wellnesssaver@adityabirlacapital.com" subject="" body="" style={{width:'100%',display:'flex'}}>
                <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',background:'rgb(241 216 214)',borderRadius:15,padding:15,}}>
                <Box><p>Email:</p><p style={{fontSize: '10px', wordBreak: 'break-word'}}>abw.wellnesssaver@adityabirlacapital.com</p></Box>
                <Box><img src={Email} alt="email"/></Box>
                </Box>
                </Mailto>
            </ListItem>
            <ListItem>
            <Callto phone="+918369644246">
            <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',background:'rgb(255 209 185)',borderRadius:15,padding:15,}}>
                <Box><p>Phone:</p><p>+91-8369644246</p></Box>
                <Box><img src={Call} alt="Call"/></Box>
                </Box>
            </Callto>
            </ListItem>
            </List>
            </Box>
        </Box>
      </Box>
  </div>;
}

export default Support;
const useStyles = makeStyles(() => ({
    Report: {
        display: "flex",
        height: "96%",
        padding:'0 2%',
        flexDirection: "column",
        background:'#fff',
        "& a": {
          textDecoration: "none",
          width:'100%'
        },
        '& .MuiListItem-gutters':{
            paddingLeft:18,
            paddingRight:18
        },
      },
     
      ReportTable:{
          width:'100%',
          paddingBottom:20,
          '& p':{
              fontFamily:'Poppins',
              fontSize:16,
              margin:0,
              fontWeight:'500'
          }
      },
      DownloadBtn:{
        fontFamily: "Poppins",
        fontSize: 15,
        textTransform: "capitalize",
        color:'#3499f1'
      },
      ReportRow:{
          width:'90%',
          padding:'10px 5%',
          display:'flex',
          justifyContent:'space-between',
          borderBottom:'1px rgba(0,0,0,0.07) solid',
          '& p':{
              fontFamily:'Poppins',
              fontSize:16,
              color:'#3698ef',
              textAlign:'left',
              fontWeight:'600',
              margin:0
          },
          '& h6':{
            fontFamily:'Poppins',
            fontSize:15,
            color:'#1d1d1d',
            textAlign:'left',
            fontWeight:'600',
            margin:0,
            '& span':{
                marginLeft:5,
            }
        },
          '&:nth-child(even)':{
              background:'#fff'
          },
          '&:nth-child(odd)':{
            background:'#ebebeb'
        },
      },
      logo: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "12%",
        background:'#fff',
        padding:'15px 0',
        borderBottom:'1px rgba(0,0,0,0.05) solid',
      },
      ReportBtn: {
        fontFamily: "Poppins",
        fontSize: 15,
        color: "#fff",
        textTransform: "capitalize",
        padding: "0 20px",
        height: "50px",
        borderRadius: "50px",
        background:'#2F99F1',
      },
      ReportTop:{
          width:'90%',
          padding:'0 5%',
          marginBottom:15,
          marginTop:10,
          display:'flex',
          justifyContent:'space-between',
          alignItems:'center',
          '& h3':{
              fontFamily:'Poppins',
              fontSize:20,
          }
      }
   }));