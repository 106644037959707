import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react';
  
import { makeStyles } from '@material-ui/core/styles';
import logo from '../Images/abw.png';
import risk from '../Images/risk.png';
import heart from '../Images/heart.png';
import blood from '../Images/blood.png';
import hrv from '../Images/hrv.png';
import cardiac from '../Images/cardiac.png';
import hearthealth from '../Images/heart_health2.png';
import rpm from '../Images/rpm_ad.png';
import bodyvitals from '../Images/body_vitals.png';
import physio from '../Images/physio.png';
import stress from '../Images/stress.png';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import faceIcon from '../Images/face-scan.png';
import fingerIcon from '../Images/finger-scan.png';
import Nav from '../Component/Nav';
import GaugeChart from 'react-gauge-chart'
import moment from "moment";
import {BsArrowLeftCircle} from "react-icons/bs";
import ChartData from '../Component/ChartData';
import { insightsList } from "../Util/KeyInsights";

import { apiAxios, apiAxios2 } from "../Util/ApiAxios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MotionAnimate } from 'react-motion-animate';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

function PhysioFitness(props) {
    const classes = useStyles();
    const [greetings, setGreetings] = React.useState("Welcome,");
    const [userDetails, setUserDetails] = React.useState("");

    const [insights, setInsights] = React.useState([]);

    const [latestData, setLatestData] = React.useState("");

    const user = JSON.parse(localStorage.getItem("user_details")); 

  const startScan = (flag) => {

    if(flag == 1){
        props.history.push("/face-scan");
    }
    else{
        props.history.push("/finger-scan");
    }

  };  

  useEffect(() => {

    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
        setGreetings("Good Morning");
    } else if (curHr < 18) {
        setGreetings("Good Afternoon");
    } else {
        setGreetings("Good Evening");
    }

    let ins_arr = insightsList();
    setInsights(ins_arr);
    //setUserDetails( JSON.parse(localStorage.getItem("user_details")) );

    //getProfile();
    getView();


  }, []);  

  

  const getView = () =>{

    let dt = new Date();

    let data = {
        action: "list_scan_UL",
        employee_id: localStorage.getItem("emp_id"),
        table_config_rows_per_page: 1,
        table_config_page_no: 1,
      };


        apiAxios2
        .post("/users/sdk/test_api_private", data, {
            headers: {
            "Content-Type": "application/json",
            "Authorization": user.id_token
            },
        })
        .then((response) => {
            console.log(response.data);
            if (response.data.statusCode == 200) {
            
                setLatestData(response.data.result[0]);

            } else {
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
             //setShowLoader(false);
            }
        })
        .catch((error) => {
            toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            //setShowLoader(false);
        });


  }
  
    
  return <>
      <Box className={classes.Scan}>
        <Box className={classes.logo} style={{minHeight:'10%'}}>
            <Box style={{width: '80%',textAlign:'left'}}>
                <h6 className={classes.name}><Link to="/dashboard"><BsArrowLeftCircle style={{marginRight: '10%', color: '#C7222A'}} /></Link><span>Physio Fitness</span></h6>
            </Box>
            <Box>
              <Nav />
            </Box>
        </Box>
        <Box style={{display:'flex',flexDirection:'column'}}> 
        <Box style={{padding:'4%', textAlign: 'right'}}>
            <span>Weekly</span>           
        </Box>

        { latestData ?
        <>
            <Box className={classes.healthscorebox}>
                <Box className={classes.healthscoreboxleft}>
                    <GaugeChart id="gauge-chart3" 
                        nrOfLevels={20} 
                        colors={["#07bc0c", "#f1c40f", "#e74c3c"]} 
                        arcWidth={0.3} 
                        percent={(latestData.physiological_scores.bmi/100)} 
                        formatTextValue={value => latestData.physiological_scores.bmi+" kg/m2"}
                        textColor="#000"
                        needleColor="#aeaeae"
                    />
                </Box>
                <Box className={classes.healthscoreboxright}>
                    <span>Latest <br/>BMI Score</span>
                </Box>
            </Box> 
            

            <Box className={classes.vitalsBox}>  
                <h3 style={{margin: '0'}}>{latestData.physiological_scores.tbw} Ltrs</h3>
                <span>Total Body Water</span>
            </Box> 

            <Box className={classes.vitalsBox}>  
                <h3 style={{margin: '0'}}>{latestData.physiological_scores.tbwp} %</h3>
                <span>Total Body Water <br/>Percentage</span>
            </Box> 

            <Box className={classes.vitalsBox}>  
                <h3 style={{margin: '0'}}>{latestData.physiological_scores.bloodvolume} ml</h3>
                <span>Blood Volume</span>
            </Box> 

            <Box className={classes.vitalsBox}>  
                <h3 style={{margin: '0'}}>{latestData.physiological_scores.bodyfat} %</h3>
                <span>Body Fat Percentage</span>
            </Box>
        </>
        :
        <></>
        }     

                <h6
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 14,
                      color: "#C7222A",
                      marginTop: 20,
                      marginBottom: 10,
                      fontWeight: "400",
                      textAlign: "left",
                      padding: "0 10%",
                    }}
                  >
                    Key Insights
                  </h6>
                  {(insights.length > 0) ?
                  <>
                  <p className={classes.content}>{insights[3].content}</p>
                      <video
                        src={insights[3].video}
                        id="video"
                        controls="true"
                        style={{
                          width: "80%",
                          height: "100%",
                          padding: "5% 10%",
                          borderRadius: "15%",
                        }}
                      ></video>
                      <p
                        className={classes.content}
                        style={{ textAlign: "right" }}
                      >
                        Source: {insights[3].source}
                      </p>
                  </> 
                  :
                  <></>
                }   
            
             
            

              
        </Box>
      </Box>
  </>;
}

export default PhysioFitness;
const useStyles = makeStyles(() => ({
    sliderClass: {
        "& .control-dots": {
            display: "none",
          },
          "& .carousel .slider": {
            height: "auto",
          },
          "& .carousel.carousel-slider .control-arrow": {
            bottom: "initial",
            marginTop: '30%'
          },
          '& .carousel .thumbs-wrapper':{
              margin: '0'
          },
          "& .carousel .control-next.control-arrow:before": {
            borderLeft: "8px solid #333",
          },
          "& .carousel .control-prev.control-arrow:before": {
            borderRight: "8px solid #333",
          },
          "& .carousel-status": {
            display: "none",
          },
          "& .carousel .control-next.control-arrow": {
            right: 5,
          },
          "& .carousel.carousel-slider .control-arrow:hover": {
            background: "#fff",
          },
          "& .carousel .control-prev.control-arrow": {
            left: 5,
          }

    },
    vitalsBox:{
        border: '2px solid #e9eef3',
        padding: '10% 0',
        margin: '5% 0 0 0',
        borderRadius: '10px',
        backgroundColor: '#e9eef3'
    },
    Scan:{
        display:'flex',
        height:'100vh',
        padding:'0 7.5%',
        left: 0,
        right:'0',
        background: 'linear-gradient(180deg, #f4f4f5, white)',
        flexDirection:'column',
        '& a':{
            textDecoration:'none'
        },
        
    },
    content: {
        textAlign: "justify",
        fontSize: "12px",
        fontWeight: "200",
        padding: "0 10%",
        color: "#464646",
    },
    healthscorebox:{
        width:'100%',
        background: 'linear-gradient(176deg, #eaf0f5 0%, #e9ecef 92.7%)',
        borderRadius: '10px',
        height: '25%',
    },
    scanbox:{
        height: '25%',
        width:'100%',
    },
    healthscoreboxleft:{
        width:'65%',
        float:'left',
        height:'100%',
        paddingTop: '5%'
    },
    histIcon:{
        width: '25%',
        textAlign:'center',
        float: 'left'
    },
    histText:{
        fontFamily: 'Poppins',
        fontSize: '0.8em',
        fontWeight: '300',
        margin: '0'
    },
    scanboxleft:{
        width:'45%',
        float:'left',
        textAlign:'left',
        '& p':{
            fontSize: '12px',
            color: '#000',
            fontFamily:'Poppins',
        }
    },
    healthscoreboxright:{
        width:'35%',
        color:'#1a2e5c',
        paddingTop: '12%',
        float:'left',
        textAlign:'left',
        height:'100%',
    },
    scanboxright:{
        width:'55%',
        float:'left',
        textAlign:'center',
    },
    riskbar:{
        padding:'10%', 
        height: '2.5em', 
        marginTop: '15%', 
        borderRadius:'100%', 
        border: '3px solid #fff'
    },
    subScan:{
        fontSize:'0.7em',
        color: '#5c6374',
        margin: '0',
        padding: '0 10%',
        fontFamily:'Poppins',
    },
    optionIcon:{
       width: '100%',
       textAlign: 'center',
       height: '50%',
       '& h4':{
        fontFamily:'Poppins',
        color:'#C7222A',
        fontWeight:'400',
        fontSize:16,
        marginTop:10,
        marginBottom:10,
        }, 
    },
    ScanInfo:{
        width:'100%',
        background:'linear-gradient(180deg, #C7222A -92.7%, #C7222A 92.7%)',
        height:'38vh',
        '& p':{
            fontFamily:'Poppins',
            color:'rgba(255,255,255,0.7)',
            fontWeight:'400',
            fontSize:12,
            padding:'0 20px',
            marginTop:0,
            marginBottom:30,
            lineHeight:1.6,
        },
        '& h4':{
            fontFamily:'Poppins',
            color:'rgba(255,255,255,0.8)',
            fontWeight:'400',
            fontSize:16,
            marginTop:20,
            marginBottom:10,
        },
        '& h3':{
            fontSize:16,
            fontFamily:'Poppins',
            color:'#fff',
            fontWeight:'400',
            marginTop:40,
            marginBottom:0,
            '& span':{
                fontSize:24,
                fontWeight:'600',
                color:'#C7222A',
                marginRight:5,
            }
        }
    },
    ScanCamera:{
        width:'100%',
        height:'49.7vh'
    },
    logo:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        width:'100%',
        height:'15%',
    },
    CancelBtn:{
        fontFamily:'Poppins',
        fontSize:16,
        color:'#C7222A',
        textTransform:'capitalize',
        borderRadius:50,
        padding:'5px 20px'
    },
    
    loginbtn:{
        background:'linear-gradient(90deg, #C7222A 0%, #C7222A 100%)',
        padding:'0 20px',
        width:'162px',
        height:'50px',
        borderRadius:'50px',
        color:'#fff',
        marginTop:'0',
        textTransform:'capitalize',
        boxShadow: '0 0 0 0 #2680db24',
        animation: 'pulse 1.5s infinite',
        
    },
    name:{
        margin: 0,
        fontSize: '1.4em',
        fontFamily:'Poppins',
        fontWeight: '600'
    },
    greeting:{
        fontWeight: '300',
        color: '#C7222A',
        marginBottom: 0
    },
    '@keyframes pulse': {
        '0%': {
            transform: 'scale(.9)',
          },
          '70%': {
            transform: 'scale(1)',
            boxShadow: '0 0 0 50px #2680db24',
          },
        '100%': {
            transform: 'scale(.9)',
            boxShadow: '0 0 0 0 #2680db24',
          }
    }

   }));