import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../Images/abw.png';
import risk from '../Images/risk.png';
import heart from '../Images/heart.png';
import blood from '../Images/blood.png';
import tutorialVideo from '../Images/faceTutorial.mp4';
import hrv from '../Images/hrv.png';
import cardiac from '../Images/cardiac.png';
import hearthealth from '../Images/heart_health2.png';
import rpm from '../Images/rpm_ad.png';
import bodyvitals from '../Images/body_vitals.png';
import physio from '../Images/physio.png';
import stress from '../Images/stress.png';
import tutorial from '../Images/tutorial.png';
import report from '../Images/report.png';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import faceIcon from '../Images/face-scan.png';
import fingerIcon from '../Images/finger-scan.png';
import Nav from '../Component/Nav';
import GaugeChart from 'react-gauge-chart'
import moment from "moment";
import loaderGif from "../Images/loader.gif";

import { apiAxios, apiAxios2 } from "../Util/ApiAxios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MotionAnimate } from 'react-motion-animate'

function Dashboard(props) {
    const classes = useStyles();
    const [greetings, setGreetings] = React.useState("Welcome,");
    const [userDetails, setUserDetails] = React.useState("");

    const [showLoader, setShowLoader] = React.useState(false);

    const [latestData, setLatestData] = React.useState("");

    const user = JSON.parse(localStorage.getItem("user_details")); 

    const videoEl = useRef(null);
    const attemptPlay = () => {
        videoEl &&
          videoEl.current &&
          videoEl.current.play().catch((error) => {
            console.error("Error attempting to play", error);
          });
      };

  const startScan = (flag) => {

    if(flag == 1){
        props.history.push("/face-scan");
    }
    else{
        props.history.push("/finger-scan");
    }

  };  

  useEffect(() => {

    setShowLoader(true);
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
        setGreetings("Good Morning");
    } else if (curHr < 18) {
        setGreetings("Good Afternoon");
    } else {
        setGreetings("Good Evening");
    }

    
    //setUserDetails( JSON.parse(localStorage.getItem("user_details")) );

    let ud = JSON.parse(localStorage.getItem("user_details"));

    if(ud){
        getProfile();

        
        

        if(localStorage.getItem("scan_result")){
            localStorage.removeItem("scan_result");
        }

    }

    

  }, []);  

  const startScanClick = () => {
      window.location.href="/select-option";
  }

  const getProfile = () =>{

    let data = {
        
      };


        apiAxios2
        .post("/users/profile/view", data, {
            headers: {
            "Content-Type": "application/json",
            "Authorization": user.id_token
            },
        })
        .then((response) => {
            console.log(response.data);
            if (response.data.statusCode == 200) {
            
            localStorage.setItem("emp_id", response.data.profile.employee_id);


            localStorage.setItem("height", response.data.profile.height);
            localStorage.setItem("weight", response.data.profile.weight);

            let ud = JSON.parse(localStorage.getItem("user_details"));
            ud.first_name = response.data.profile.first_name;
            ud.last_name = response.data.profile.last_name;
            localStorage.setItem("user_details", JSON.stringify(ud));

            if(response.data.profile.first_name == "" || response.data.profile.last_name == null){
                props.history.push("/basic-details");
            }
            else {
                getSurvey();
                getView();
                setShowLoader(false);
            }
            

            } else {
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setShowLoader(false);
            }
        })
        .catch((error) => {
            toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            setShowLoader(false);
        });


  }

  const getSurvey = () =>{
    
    let userDetails = JSON.parse(localStorage.getItem("user_details"));

    let data = {
        table_config_rows_per_page: 10,
        table_config_page_no: 1,
      };
      apiAxios2
        .post("/users/questions/list", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: userDetails.id_token,
          },
        })
        .then((response) => {
          let data = response.data.response.response_packages;
          
          if(data[0].question_answers == "" || data[0].question_answers == null){
            props.history.push("/survey");
          }
          
        })
        .catch((error) => {
          console.log(error);
        });

  }

  const getView = () =>{

    let dt = new Date();

    let data = {
        action: "list_scan_UL",
        employee_id: localStorage.getItem("emp_id"),
        table_config_rows_per_page: 1,
        table_config_page_no: 1,
      };


        apiAxios2
        .post("/users/sdk/test_api_private", data, {
            headers: {
            "Content-Type": "application/json",
            "Authorization": user.id_token
            },
        })
        .then((response) => {
            console.log(response.data);
            if (response.data.statusCode == 200) {
            
                setLatestData(response.data.result[0]);
                localStorage.setItem("latest_scan", response.data.result[0].scan_id);

            } else {
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
             //setShowLoader(false);
            }
        })
        .catch((error) => {
            toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            //setShowLoader(false);
        });


  }
  
    
  return <>



      { showLoader ?  
        <Box style={{ margin: "50% 0", width: "100%", textAlign: "center" }}>
        <img
            src={loaderGif}
            alt="loader"
            style={{ width: "80px", height: "80px" }}
        />
        </Box>  
        :  
      <Box className={classes.Scan}>  
        <Box className={classes.logo}>

            {user.first_name ?
            <Box style={{width: '40%',textAlign:'left'}}>
                <p className={classes.greeting}>{greetings}</p>
                {(user.first_name.length > 10) ?
                <h6 className={classes.name}>{user.first_name.substring(0,10)+ "..."}</h6>
                :
                <h6 className={classes.name}>{user.first_name}</h6>
                }
            </Box>
            :
            <></>
            }
            <Box>
              <Nav />
            </Box>
        </Box>
        <Box style={{display:'flex',flexDirection:'column', height:'85%'}}> 
            
            <Box className={classes.scanbox}>
                <Box className={classes.scanboxleft}>
                    <h6 className={classes.name} style={{lineHeight:'1em',marginTop:'20%', marginBottom:'2%' }}>Monitor <br/>Your Health</h6>
                    <p style={{lineHeight:'15px'}}>Do a self health-check in less than one minute.</p> 
                </Box>
                <Box className={classes.scanboxright}>
                    
                        <Button size="large" className={classes.loginbtn} style={{marginTop:'25%'}} onClick={startScanClick}>
                            Start A Scan
                        </Button>
                   
                </Box>     
            </Box>
            
            {/* <Box className={classes.ScanCamera} style={{height: 'auto', textAlign:'left'}}>
            <h6 className={classes.name} style={{lineHeight:'1em',marginTop:'5%', marginBottom:'2%', fontSize: '1rem', fontWeight: '400' }}>How it works?</h6>
                <video
                style={{ maxWidth: "100%", width: "800px", margin: "2% auto", borderRadius: '20px'  }}
                playsInline
                loop
                muted
                controls
                alt="All the devices"
                src={tutorialVideo}
                ref={videoEl}
                />
            </Box> */}
            
            {/* <Box className={classes.scanbox} style={{height:'25%'}}>
                <Box className={classes.scanboxleft} style={{width:'100%', margin:'5% 0',padding:'0'}}>
                    <h6 className={classes.name} style={{lineHeight:'1em',marginTop:'5%', marginBottom:'2%' }}>Vitals History</h6>
                        <Box className={classes.histIcon}>
                            <img src={heart} alt="health risk" style={{padding:'10%', width: '50%' }} />
                            <h5 className={classes.histText}>Key Vitals</h5>
                        </Box>
                        <Box className={classes.histIcon}>
                            <img src={blood} alt="health risk" style={{padding:'10%', width: '50%' }} />
                            <h5 className={classes.histText}>Physiological</h5>
                        </Box>
                        <Box className={classes.histIcon}>
                            <img src={cardiac} alt="health risk" style={{padding:'10%', width: '50%' }} />
                            <h5 className={classes.histText}>Cardiac</h5>
                        </Box>
                        <Box className={classes.histIcon}>
                            <img src={hrv} alt="health risk" style={{padding:'10%', width: '50%' }} />
                            <h5 className={classes.histText}>HRV & Stress</h5>
                        </Box>
                
                
            </Box> */}

                {latestData ?
                <Box className={classes.healthscorebox}>
                    <Box className={classes.healthscoreboxleft}>
                        <GaugeChart id="gauge-chart3" 
                            nrOfLevels={20} 
                            colors={["#07bc0c", "#f1c40f", "#e74c3c"]} 
                            arcWidth={0.3} 
                            percent={(latestData.health_risk_score/100)} 
                            textColor="#000"
                            needleColor="#aeaeae"
                            />    
                    </Box>
                    <Box className={classes.healthscoreboxright}>
                        <span>Your Health <br/>Risk Score</span>
                        {latestData.health_risk_score == "--" ? 
                        <></>
                        :     
                        <Box style={{width:'100%', textAlign: 'left', margin:'5% 0'}}>
                        {(latestData.health_risk_score <= 30 ) ?
                        <h3 style={{color: '#32b509', margin:'2% 0'}}>HEALTHY</h3>
                        :
                        (latestData.health_risk_score > 30 && latestData.health_risk_score <= 55 ) ?
                          <h3 style={{color: '#61b80d', margin:'2% 0'}}>LOW</h3>
                          :
                          (latestData.health_risk_score > 55 && latestData.health_risk_score <= 80 ) ?
                            <h3 style={{color: '#eea508', margin:'2% 0'}}>MODERATE</h3>
                            :
                            <h3 style={{color: '#f31e2e', margin:'2% 0'}}>HIGH</h3>
                        }
                        </Box>
                        }
                    </Box>
                </Box>  
                :
                <></>
                }

                <Box className={classes.scanbox} style={{height:'auto'}}>
                    <Box className={classes.scanboxleft} style={{width:'100%', margin:'5% 0',padding:'0'}}>
                        {/* <Link to="/report">
                            <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                                <img src={report} alt="report" style={{borderRadius:'10px', width: '100%' }} />
                            </Box>
                        </Link> */}
                        <Link to="/tutorial-list">
                            <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                                <img src={tutorial} alt="tutorial" style={{borderRadius:'10px', width: '100%' }} />
                            </Box>
                        </Link>
                    </Box>
                </Box>

            { latestData ? 
            <Box className={classes.scanbox} style={{height:'25%'}}>
                <Box className={classes.scanboxleft} style={{width:'100%', margin:'5% 0',padding:'0'}}>
                    <h6 className={classes.name} style={{lineHeight:'1em',marginTop:'5%', marginBottom:'2%', fontSize: '1rem', fontWeight: '400' }}>My Activities</h6>
                    <Link to="/vitals">
                        <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                            <img src={bodyvitals} alt="key vitals" style={{borderRadius:'10px', width: '100%' }} />
                        </Box>
                    </Link>
                    <Link to="/heart">
                        <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                            <img src={hearthealth} alt="heart health" style={{borderRadius:'10px', width: '100%' }} />
                        </Box>
                    </Link>
                    <Link to="/physiological">
                        <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                            <img src={physio} alt="phyiological" style={{borderRadius:'10px', width: '100%' }} />
                        </Box>
                    </Link>
                    <Link to="/stress">
                        <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                            <img src={stress} alt="heart health" style={{borderRadius:'10px', width: '100%' }} />
                        </Box>
                    </Link>
                    
                </Box>
            </Box>
            :
            <Box className={classes.scanbox} style={{height:'25%'}}>
                <Box className={classes.scanboxleft} style={{width:'100%', margin:'5% 0',padding:'0'}}>
                    <h6 className={classes.name} style={{lineHeight:'1em',marginTop:'5%', marginBottom:'2%', fontSize: '1rem', fontWeight: '400' }}>My Activities</h6>
                    
                        <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                            <img src={bodyvitals} alt="key vitals" style={{borderRadius:'10px', width: '100%', opacity: '0.40', filter: 'grayscale(1)' }} />
                        </Box>
                    
                    
                        <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                            <img src={hearthealth} alt="heart health" style={{borderRadius:'10px', width: '100%', opacity: '0.40', filter: 'grayscale(1)' }} />
                        </Box>
                    
                    
                        <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                            <img src={physio} alt="phyiological" style={{borderRadius:'10px', width: '100%', opacity: '0.40', filter: 'grayscale(1)' }} />
                        </Box>
                    
                    
                        <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                            <img src={stress} alt="heart health" style={{borderRadius:'10px', width: '100%', opacity: '0.40', filter: 'grayscale(1)' }} />
                        </Box>
                    
                    
                </Box>
            </Box>
            }

              
        </Box>
      </Box>
      }
  </>;
}

export default Dashboard;
const useStyles = makeStyles(() => ({
    Scan:{
        display:'flex',
        height:'100vh',
        padding:'0 7.5%',
        left: 0,
        right:'0',
        background: 'linear-gradient(180deg, #f4f4f5, white)',
        flexDirection:'column',
        '& a':{
            textDecoration:'none'
        }
    },
    healthscorebox:{
        width:'100%',
        background: 'linear-gradient(176deg, #eaf0f5 0%, #e9ecef 92.7%)',
        borderRadius: '10px',
        height: '20%',
    },
    scanbox:{
        height: '25%',
        width:'100%',
    },
    healthscoreboxleft:{
        width:'65%',
        float:'left',
        height:'100%',
        paddingTop: '5%'
    },
    histIcon:{
        width: '25%',
        textAlign:'center',
        float: 'left'
    },
    histText:{
        fontFamily: 'Poppins',
        fontSize: '0.8em',
        fontWeight: '300',
        margin: '0'
    },
    scanboxleft:{
        width:'45%',
        float:'left',
        textAlign:'left',
        '& p':{
            fontSize: '12px',
            color: '#000',
            fontFamily:'Poppins',
        }
    },
    healthscoreboxright:{
        width:'35%',
        color:'#1a2e5c',
        paddingTop: '8%',
        float:'left',
        textAlign:'left',
        height:'100%',
    },
    scanboxright:{
        width:'55%',
        float:'left',
        textAlign:'center',
    },
    riskbar:{
        padding:'10%', 
        height: '2.5em', 
        marginTop: '15%', 
        borderRadius:'100%', 
        border: '3px solid #fff'
    },
    subScan:{
        fontSize:'0.7em',
        color: '#5c6374',
        margin: '0',
        padding: '0 10%',
        fontFamily:'Poppins',
    },
    optionIcon:{
       width: '100%',
       textAlign: 'center',
       height: '50%',
       '& h4':{
        fontFamily:'Poppins',
        color:'#C7222A',
        fontWeight:'400',
        fontSize:16,
        marginTop:10,
        marginBottom:10,
        }, 
    },
    ScanInfo:{
        width:'100%',
        background:'linear-gradient(180deg, #C7222A -92.7%, #C7222A 92.7%)',
        height:'38vh',
        '& p':{
            fontFamily:'Poppins',
            color:'rgba(255,255,255,0.7)',
            fontWeight:'400',
            fontSize:12,
            padding:'0 20px',
            marginTop:0,
            marginBottom:30,
            lineHeight:1.6,
        },
        '& h4':{
            fontFamily:'Poppins',
            color:'rgba(255,255,255,0.8)',
            fontWeight:'400',
            fontSize:16,
            marginTop:20,
            marginBottom:10,
        },
        '& h3':{
            fontSize:16,
            fontFamily:'Poppins',
            color:'#fff',
            fontWeight:'400',
            marginTop:40,
            marginBottom:0,
            '& span':{
                fontSize:24,
                fontWeight:'600',
                color:'#C7222A',
                marginRight:5,
            }
        }
    },
    ScanCamera:{
        width:'100%',
        height:'49.7vh'
    },
    logo:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        width:'100%',
        height:'15%',
    },
    CancelBtn:{
        fontFamily:'Poppins',
        fontSize:16,
        color:'#C7222A',
        textTransform:'capitalize',
        borderRadius:50,
        padding:'5px 20px'
    },
    
    loginbtn:{
        background:'linear-gradient(90deg, #C7222A 0%, #C7222A 100%)',
        padding:'0 20px',
        width:'162px',
        height:'50px',
        borderRadius:'50px',
        color:'#fff',
        marginTop:'0',
        textTransform:'capitalize',
        boxShadow: '0 0 0 0 #2680db24',
        animation: 'pulse 1.5s infinite',
        
    },
    name:{
        margin: 0,
        fontSize: '1.4em',
        fontFamily:'Poppins',
        fontWeight: '600'
    },
    greeting:{
        fontWeight: '300',
        color: '#C7222A',
        marginBottom: 0
    },
    '@keyframes pulse': {
        '0%': {
            transform: 'scale(.9)',
          },
          '70%': {
            transform: 'scale(1)',
            boxShadow: '0 0 0 50px #2680db24',
          },
        '100%': {
            transform: 'scale(.9)',
            boxShadow: '0 0 0 0 #2680db24',
          }
    }

   }));